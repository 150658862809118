.stats_container_cell {
    margin: 0;
    background-color: #F7F6FA;
    padding: 10px 12px;
    border-radius: 6px;
}

.stats_container_cell_counter {
    color: #334155;
    text-align: right;
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
}

.stats_container_cell_name {
    color: rgba(51, 65, 85, 0.80);
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
}

.stats_container_inventory {
    padding: 20px 30px;
    border-radius: 15px;
    background-color: #fff;
}

.stats_container_inventory_title {
    color: #000;
    font-size: 24px;
    font-weight: 400;
    text-align: left;
}

.stats_container_inventory_title.mb15 {
    margin-bottom: 15px;
}

.stats_container_inventory.inline {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.stats_link_plain {
    color: #37394F;
    font-size: 24px;
    font-weight: 500;
}